import { navigate } from "gatsby";
import React from "react";

import { Heading, Layout, PrimaryButton } from "~/components";

const NotFound = () => {
  return (
    <Layout>
      <Heading className="text-center" type="h2">
        Page not found!
      </Heading>
      <div className="text-center">
        <PrimaryButton onClick={() => navigate("/")}>Go Home</PrimaryButton>
      </div>
    </Layout>
  );
};

export default NotFound;
